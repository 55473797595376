// Generated by Framer (bdd6aa1)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["Yyha9riwU", "cbBMVw60h"];

const variantClassNames = {cbBMVw60h: "framer-v-1c4xjep", Yyha9riwU: "framer-v-13qsvu"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 30, delay: 0, mass: 1, stiffness: 400, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {Burger: "Yyha9riwU", X: "cbBMVw60h"}

const getProps = ({color, height, id, tap, width, ...props}) => { return {...props, iWF8EWIN1: color ?? props.iWF8EWIN1 ?? "rgb(136, 136, 136)", TBgHLr7MW: tap ?? props.TBgHLr7MW, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "Yyha9riwU"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;tap?: any;color?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, TBgHLr7MW, iWF8EWIN1, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "Yyha9riwU", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap1updaio = activeVariantCallback(async (...args) => {
if (TBgHLr7MW) {
const res = await TBgHLr7MW(...args);
if (res === false) return false;
}
setVariant("cbBMVw60h")
})

const onTapirf6bf = activeVariantCallback(async (...args) => {
if (TBgHLr7MW) {
const res = await TBgHLr7MW(...args);
if (res === false) return false;
}
setVariant("Yyha9riwU")
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-W8Jkj", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-13qsvu", className)} data-framer-name={"Burger"} data-highlight layoutDependency={layoutDependency} layoutId={"Yyha9riwU"} onTap={onTap1updaio} ref={ref} style={{...style}} {...addPropertyOverrides({cbBMVw60h: {"data-framer-name": "X", onTap: onTapirf6bf}}, baseVariant, gestureVariant)}><motion.div className={"framer-1yauz1g"} layoutDependency={layoutDependency} layoutId={"iccC9cnkL"}><motion.div className={"framer-12dx5dd"} data-framer-name={"Bottom"} layoutDependency={layoutDependency} layoutId={"Mx1Mm1IMs"} style={{backgroundColor: iWF8EWIN1, rotate: 0}} variants={{cbBMVw60h: {rotate: -45}}}/><motion.div className={"framer-namz3t"} data-framer-name={"Mid"} layoutDependency={layoutDependency} layoutId={"Wsj16RGSe"} style={{backgroundColor: iWF8EWIN1, opacity: 1}} variants={{cbBMVw60h: {opacity: 0}}}/><motion.div className={"framer-16hy3t3"} data-framer-name={"Top"} layoutDependency={layoutDependency} layoutId={"qAeJv3ed8"} style={{backgroundColor: iWF8EWIN1, rotate: 0}} variants={{cbBMVw60h: {rotate: 45}}}/></motion.div></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-W8Jkj [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-W8Jkj .framer-1fi7u5x { display: block; }", ".framer-W8Jkj .framer-13qsvu { cursor: pointer; height: 32px; overflow: hidden; position: relative; width: 32px; }", ".framer-W8Jkj .framer-1yauz1g { flex: none; height: 18px; left: calc(50.00000000000002% - 24px / 2); overflow: visible; position: absolute; top: 7px; width: 24px; }", ".framer-W8Jkj .framer-12dx5dd { bottom: 0px; flex: none; height: 2px; left: 0px; overflow: hidden; position: absolute; right: 0px; }", ".framer-W8Jkj .framer-namz3t { flex: none; height: 2px; left: 0px; overflow: hidden; position: absolute; right: 0px; top: calc(50.00000000000002% - 2px / 2); }", ".framer-W8Jkj .framer-16hy3t3 { flex: none; height: 2px; left: 0px; overflow: hidden; position: absolute; right: 0px; top: 0px; }", ".framer-W8Jkj.framer-v-1c4xjep .framer-12dx5dd { bottom: unset; top: calc(50.00000000000002% - 2px / 2); }", ".framer-W8Jkj.framer-v-1c4xjep .framer-namz3t { left: unset; width: 2px; }", ".framer-W8Jkj.framer-v-1c4xjep .framer-16hy3t3 { left: unset; right: -1px; top: calc(50.00000000000002% - 2px / 2); width: 26px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 32
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"cbBMVw60h":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"TBgHLr7MW":"tap","iWF8EWIN1":"color"}
 */
const Framercpy7Tsu6H: React.ComponentType<Props> = withCSS(Component, css, "framer-W8Jkj") as typeof Component;
export default Framercpy7Tsu6H;

Framercpy7Tsu6H.displayName = "Elements/Menu Icon";

Framercpy7Tsu6H.defaultProps = {height: 32, width: 32};

addPropertyControls(Framercpy7Tsu6H, {variant: {options: ["Yyha9riwU", "cbBMVw60h"], optionTitles: ["Burger", "X"], title: "Variant", type: ControlType.Enum}, TBgHLr7MW: {title: "Tap", type: ControlType.EventHandler}, iWF8EWIN1: {defaultValue: "rgb(136, 136, 136)", title: "Color", type: ControlType.Color}} as any)

addFonts(Framercpy7Tsu6H, [])